import React from "react"
import styled from "styled-components"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar as faStarOpen } from "@fortawesome/pro-regular-svg-icons"
import { faStar, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons"

import { size, mq, colors, fontFamily } from "../global.style"

import { WysiWyg } from "./core/WysiWyg"
import Img from "gatsby-image"

function Map({ areas }) {
  const [activeArea, setActiveArea] = React.useState(null)
  const [map, setMap] = React.useState(null) // eslint-disable-line no-unused-vars
  const [sideBarOpen, setSideBarOpen] = React.useState(false) // eslint-disable-line no-unused-vars

  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 6.25rem)",
  }

  const center = {
    lat: 51.260197,
    lng: 4.402771,
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const showDetails = (area, index) => {
    setActiveArea(area)
    console.log(area)
  }

  React.useEffect(() => {
    if (map && activeArea) {
      map.panTo(activeArea.location)
    }
  }, [activeArea, map])

  return (
    <>
      <Wrapper>
        <Areas sideBarOpen={sideBarOpen}>
          <Title>Uitlaat-gebieden</Title>
          <AreasList>
            {areas.nodes &&
              areas.nodes.map((area, i) => (
                <AreaListItem key={`key_${i}`}>
                  <Area
                    href="#"
                    key={`key_area_${i}`}
                    onClick={e => showDetails(area)}
                    selectedArea={
                      activeArea && area.title === activeArea.title
                        ? true
                        : false
                    }
                  >
                    <FontAwesomeIcon size="lg" icon={faMapMarkerAlt} />
                    <AreaTitle>{area.title}</AreaTitle>
                  </Area>
                </AreaListItem>
              ))}
          </AreasList>
        </Areas>
        <div>
          <LoadScript googleMapsApiKey="AIzaSyC2_gWwM7vhd63QzY6Wr2-wdD_NnLQ2Tcw">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={9}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {areas &&
                areas.nodes.map((area, i) => (
                  <Marker
                    key={`key_area_${i}`}
                    animation="DROP"
                    position={area.location}
                    onClick={e => showDetails(area)}
                  >
                    {activeArea && area === activeArea && (
                      <InfoWindow visible={false}>
                        <div>
                          <h3>{activeArea && activeArea.title}</h3>
                          <p>{activeArea.address}</p>
                          <p>
                            <WysiWyg blocks={activeArea._rawMessage} />
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </Wrapper>
      <div>
        {activeArea && (
          <AreaDetail>
            <Title>{activeArea.title}</Title>
            <Stars>
              {Array.from(Array(activeArea.stars)).map((x, i) => (
                <FontAwesomeIcon icon={faStar} key={`key_${i}`} />
              ))}
              {Array.from(Array(5 - activeArea.stars)).map((x, i) => (
                <FontAwesomeIcon icon={faStarOpen} key={`key_open_${i}`} />
              ))}
            </Stars>
            <p>{activeArea.address}</p>
            {activeArea.image !== null && (
              <ImgStyled>
                <Img fluid={activeArea.image.asset.fluid}></Img>
              </ImgStyled>
            )}
            <Message>
              <WysiWyg blocks={activeArea._rawMessage} />
            </Message>
          </AreaDetail>
        )}
      </div>
    </>
  )
}

export default React.memo(Map)

const Wrapper = styled.div`
  ${mq.minWidth(size.laptop)} {
    display: grid;
    grid-template-columns: 400px 1fr;
  }
`

const Areas = styled.aside`
  padding: 2rem 1rem 0 1rem;
  background-color: #f2f2f2;

  ${mq.minWidth(size.laptop)} {
    overflow-y: scroll;
    height: calc(100vh - 6.25rem);
  }
  /* ${mq.maxWidth(size.laptop)} {
    width: 400px;
    position: absolute;
    ${props => (props.sideBarOpen ? `left: 0;` : `left: -400px;`)}
    top: 6.25rem;
    z-index: 999999;
  } */
`

const Area = styled.a`
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  color: ${props => (props.selectedArea ? `${colors.sec}` : `${colors.text}`)};

  svg {
    margin-right: 0.5rem;
  }
`
const AreaTitle = styled.span``

const AreasList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: none;

  ${mq.minWidth(size.laptop)} {
    display: block;
  }
`

const AreaListItem = styled.li`
  ${fontFamily("OpenSansSemiBold")};
  margin: 0;
  font-size: 0.8rem;
  margin-bottom: 0.75rem;

  ${mq.minWidth(size.portrait)} {
    font-size: 1rem;
    text-align: left;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1rem;
  }
`

const Title = styled.h1`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1;
  margin: 0;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  span {
    padding-right: 0.5rem;

    ${mq.minWidth(size.portrait)} {
      padding-right: 0.5rem;
    }
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
  }
`

const AreaDetail = styled.div`
  padding: 2rem;
`

const Stars = styled.div`
  color: ${colors.text};
  margin-bottom: 1rem;

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const Message = styled.div`
  margin: 0;
  color: ${colors.text};
  ${fontFamily("OpenSans")};
  line-height: 1.6;

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
  }

  strong {
    ${fontFamily("OpenSansSemiBold")};
  }
`

const ImgStyled = styled.div`
  max-width: 500px;
`
