import React from "react"
import Map from "../components/Map"
import Layout from "../Layout"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"
export default function Gebieden() {
  const areaData = useStaticQuery(graphql`
    query Areas {
      allSanityArea {
        nodes {
          title
          stars
          location {
            lng
            lat
          }
          id
          image {
            asset {
              fluid(maxWidth: 430) {
                ...GatsbySanityImageFluid
              }
            }
          }
          address
          _rawMessage
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/gebieden" />
      {areaData && areaData.allSanityArea && (
        <Map areas={areaData.allSanityArea} />
      )}
    </Layout>
  )
}
